import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"

const Container = tw.div`
  col-span-6 
  sm:col-span-2 
  bg-white 
  rounded-2xl 
  py-4 
  md:py-10 
  px-2
  xs:px-8 
  md:px-12
`

export default function CardSmall({ data }) {
  const [imgUrl, setImgUrl] = useState("")
  const [content, setContent] = useState("")
  const [title, setTitle] = useState([])

  useEffect(() => {
    const { cardTitle, description, cardImg } = data
    setImgUrl(cardImg?.sourceUrl)
    setContent(description)
    setTitle(cardTitle)
  }, [data])

  return (
    <Container>
      <h3
        className={`pb-4 text-center text-xl font-bold sm:text-start sm:text-2xl`}
      >
        {title.title}
      </h3>
      {content && (
        <p className="pb-10 text-center text-base font-normal sm:text-start">
          {content}
        </p>
      )}
      {imgUrl && (
        <div className="flex flex-col items-center sm:items-start">
          <img className="w-56" src={imgUrl} alt="#rates" />
        </div>
      )}
    </Container>
  )
}
