import clubSwanLogo from "../../static/clubswanLogo.svg"
import auLifeStyleLogo from "../../static/auraeLogo.svg"
import arrowIcon from "../../static/arrowIcon.svg"

export const allData = {
  leadership: [
    { name: "First and last name", position: "Founder and CEO" },
    { name: "First and last name", position: "Founder and CEO" },
    { name: "First and last name", position: "Founder and CEO" },
  ],

  team: [
    { name: "First and last name", position: "Position" },
    { name: "First and last name", position: "Position" },
    { name: "First and last name", position: "Position" },
    { name: "First and last name", position: "Position" },
  ],

  investors: [1, 2, 3, 4, 5, 6],

  company: {
    about: {
      title: "About Esntl",
      info: [
        { amount: "50K", name: "Accounts" },
        { amount: "$500M", name: "Crypto Processed" },
        { amount: "138", name: "Staff" },
      ],
      description: {
        title: "Header",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse cursus quam eu molestie lobortis. Donec tellus ligula, lobortis eu ante eget, dictum varius nibh. Maecenas et risus et mauris.",
      },
    },
  },
}

export const brandData = {
  auLifeStyle: {
    title: "Aurae Lifestyle",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse cursus quam eu molestie lobortis. Donec tellus ligula, lobortis eu ante eget, dictum varius nibh. Maecenas et risus et mauris.",
    link: "Go to  Aurae Lifestyle",
    logo: auLifeStyleLogo,
  },
  clubSwan: {
    title: "Club Swan",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse cursus quam eu molestie lobortis. Donec tellus ligula, lobortis eu ante eget, dictum varius nibh. Maecenas et risus et mauris.",
    link: "Go to  Club Swan",
    logo: clubSwanLogo,
  },
  icon: arrowIcon,
}
