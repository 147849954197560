import React, {useState } from "react"
import { Link } from "gatsby"
import tw from "tailwind-styled-components"

const StyledLink = tw(Link)`
  block 
  whitespace-pre-line 
  text-left 
  leading-9 
  -tracking-wide
  duration-300 
  hover:font-bold 
  hover:underline
`
const SubContentsStyledLink = tw(Link)`
  block 
  whitespace-pre-line 
  pl-3 
  text-left 
  leading-6 
  -tracking-wide 
  duration-300 
  hover:font-bold 
  hover:underline
`
export const ContentList = ({ title, subTitle, contents }) => {
  const [subContents, setSubContents] = useState([])
  const [selectedContent, setSelectedContent] = useState("")
  const [isShowSubContent, setIsShowSubContent] = useState(false)

  const handleClick = selectedContent => {
    setSubContents(selectedContent.subContent)
    setSelectedContent(selectedContent.name)
    if (selectedContent?.subContent?.length) {
      setIsShowSubContent(!isShowSubContent)
    }
  }
  return (
    <div className="font-BrownRegular text-xs xs:text-base">
      <h3 className="pb-3 text-xs font-bold text-brown md:pb-9	md:tracking-widest">
        {title}
      </h3>
      <h3 className="max-w-[250px] border-b-2 text-left font-bold -tracking-wider text-black md:max-w-xs">
        {subTitle}
      </h3>
      {contents?.map((item, index) => {
        return (
          <div key={index}>
            <StyledLink
              to={`${!item?.subContent?.length ? `#${index}` : ""}`}
              onClick={() => handleClick(item)}
            >
              {item.name}
            </StyledLink>
            {subContents?.length &&
            isShowSubContent &&
            item.name === selectedContent
              ? subContents?.map((subContent, subIndex) => {
                  return (
                    <div key={subIndex}>
                      <SubContentsStyledLink to={`#${index * 100 + subIndex}`}>
                        {subContent?.name}
                      </SubContentsStyledLink>
                    </div>
                  )
                })
              : ""}
          </div>
        )
      })}
    </div>
  )
}

export const ContentDetails = ({ table }) => {
  return (
    <div>
      {table?.map((item, index) => {
        return (
          item.tableDescriptions && (
            <div key={index} id={index}>
              <h2 className="mb-6 text-xl font-bold xs:text-2xl md:text-3xl">
                {item.tableName}
              </h2>
              <div
                key={index}
                dangerouslySetInnerHTML={{ __html: item.tableDescriptions }}
                className="whitespace-pre-line !leading-7 text-gray"
              />
            </div>
          )
        )
      })}
    </div>
  )
}
