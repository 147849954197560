import React from "react"
import Clipboard from "./Clipboard"
import LanguageList from "./LanguageList"
import tw from "tailwind-styled-components"

const SubContentsContainer = tw.div`
  col-span-7 
  h-[70vh] 
  max-w-xl 
  overflow-auto 
  whitespace-nowrap 
  bg-white-rgba 
  scrollbar-hide
`
const LanguageListContainer = tw.div`
  relative 
  col-span-5 
  h-[70vh] 
  overflow-auto 
  whitespace-nowrap 
  bg-gray 
  scrollbar-hide
`
const CodesContainer = tw.div`
  whitespace-pre-wrap 
  border-b-4 
  px-2 
  py-5 
  font-Inconsolata 
  text-brown
`
export default function SubContents({
  subContent,
  selectedLanguage,
  handleBtn,
  languageList,
}) {
  return (
    <div className="col-span-12 pt-6">
      {subContent?.api?.map((apiType, index) => {
        return (
          <div key={index} className="pt-6">
            <div className="grid grid-cols-12 gap-6">
              <SubContentsContainer>
                <div className="text-lg text-gray">{apiType?.name}</div>
                {apiType?.gridContents?.map((data, index) => {
                  return (
                    <div key={index}>
                      {Object.keys(data).filter(o => !data[o]).length === 0 ? (
                        <div
                          key={index}
                          className={`grid grid-cols-12 gap-6 bg-white-rgba pt-4 text-sm ${
                            index === 0 ? "font-bold" : ""
                          }`}
                        >
                          <div className="col-span-3 inline-flex items-center pl-1">
                            {data?.column1}
                          </div>
                          <div className="col-span-2 inline-flex items-center">
                            {data?.column2}
                          </div>

                          <div className="col-span-2  inline-flex items-center whitespace-nowrap">
                            {data?.column3}
                          </div>
                          <div className="col-span-2  inline-flex items-center pl-1">
                            {data?.column4}
                          </div>
                          <div className="col-span-3 inline-flex max-w-[300px] items-center text-ellipsis pl-1">
                            {data?.column5}
                          </div>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className={`grid grid-cols-12 gap-6 pt-4 ${
                            index === 0 ? "font-bold" : ""
                          } `}
                        >
                          <div className="col-span-3 inline pl-2">
                            {data?.column1}
                          </div>
                          <div className="col-span-4 inline pl-2">
                            {data?.column2}
                          </div>
                          <div className="col-span-5 inline max-w-[110px] pl-2">
                            {data?.column3}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </SubContentsContainer>
              {apiType?.codes?.length ? (
                <LanguageListContainer>
                  <LanguageList
                    languageList={languageList}
                    selectedLanguage={selectedLanguage}
                    handleBtn={handleBtn}
                  />
                  {apiType?.codes
                    ?.filter(type => type.languageName === selectedLanguage)
                    ?.map((language, index) => {
                      return (
                        <div key={index}>
                          <div className="absolute right-1 pt-4">
                            <Clipboard code={language?.code} />
                          </div>
                          <CodesContainer
                            dangerouslySetInnerHTML={{
                              __html: language?.code,
                            }}
                          ></CodesContainer>
                        </div>
                      )
                    })}
                </LanguageListContainer>
              ) : (
                <div className="col-span-5"></div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
