import React, { useEffect, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded"
export default function Clipboard(props) {

  const [value, setValue] = useState("")
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    let convertedString = props?.code.replace(/<br \/>/g, '');

    // Replace HTML entities with corresponding characters
    convertedString = convertedString.replace(/&#8220;/g, '"');
    convertedString = convertedString.replace(/&#8221;/g, '"');
  
    // Remove <p>, <span> tags and indentation
    convertedString = convertedString.replace(/<\/?p>/g, '');
    convertedString = convertedString.replace(/<span[^>]*>([\s\S]*?)<\/span>/g, '{');
    convertedString = convertedString.replace(/&nbsp;/g, '');
    setValue(convertedString)
  }, [props.code])

  const onCopy = () => {
    setCopied(true)
    setTimeout(showMessage, 1000)
    function showMessage() {
      setCopied(false)
    }
  }

  return (
    <div>
      <CopyToClipboard text={value} onCopy={onCopy}>
        <FileCopyRoundedIcon className="block cursor-pointer" />
      </CopyToClipboard>
      {copied ? (
        <span className="absolute right-0 text-white-rgba">Copied.</span>
      ) : null}
    </div>
  )
}
