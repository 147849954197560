import React from "react"
import { MenuItem, TextField } from "@mui/material"

export default function SelectVersion({
  options,
  selectedOption,
  handleChange,
  label
}) {
  return (
    <div>
      <TextField
        select
        value={selectedOption}
        defaultValue=""
        onChange={handleChange}
        label={label}
        variant="filled"
        className="w-full max-w-sm font-BrownRegular"
        InputProps={{
          classes: {
            root: "!bg-white",
            input: "!bg-white",
          },
        }}
      >
        {options?.map((item, index) => (
          <MenuItem key={index} value={item}>
            <h3>{item}</h3>
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}
