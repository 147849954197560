import React from "react"
import tw from "tailwind-styled-components"

const H2 = tw.h2`
  text-4xl 
  md:text-[54px] 
  font-bold 
  text-center 
  mt-14 
  md:mt-36 
  mb-12
`

const Image = tw.img`
  bg-white 
  aspect-square 
  max-w-[200px] 
  p-10 w-full 
  rounded-full
`

export default function Team(props) {
  const { data } = props
  return (
    <div>
      <H2>Team</H2>
      <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
        {data?.map((item, index) => {
          return (
            <div key={index} className="flex items-center gap-5">
              <Image src={item.empImage.sourceUrl} alt="#dummyIcon" />
              <div>
                <h3 className="block text-xl font-bold">{item.empName}</h3>
                <p className="pt-2 text-base text-secondary">
                  {item.empPosition}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
