import React, { useState } from "react"
import ApiContentDetails from "./ApiContentDetails"
import SubContents from "./SubContents"

export default function ApiDetails({ contents, languageList }) {
  const [selectedLanguage, setSelectedLanguage] = useState("javascript")
  const handleBtn = language => {
    setSelectedLanguage(language)
  }
  return (
    <div className="col-span-9">
      {contents?.map((item, index) => {
        return (
          <div key={index} className="grid grid-cols-12 gap-6 pt-20">
            {item["name"] && !item?.subContent?.length ? (
              <div className="col-span-12">
                <h1
                  className="border-y-2 border-dashed	 bg-white-rgba text-2xl"
                  id={index}
                >
                  {item.name}
                </h1>
                <div
                  className="pt-2"
                  dangerouslySetInnerHTML={{ __html: item?.descriptions }}
                ></div>
                <div className="mt-4 overflow-auto whitespace-nowrap bg-white-rgba scrollbar-hide">
                  <ApiContentDetails item={item} />
                </div>
              </div>
            ) : (
              <div className="col-span-12">
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-12">
                    <h1
                      className="border-y-2 border-dashed bg-white-rgba text-2xl"
                      id={index}
                    >
                      {item.name}
                    </h1>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.descriptions }}
                    ></div>
                  </div>
                  <ApiContentDetails item={item} />
                  <div className="col-span-5 bg-gray"></div>
                  {item?.subContent?.length &&
                    item.subContent.map((subContent, subIndex) => {
                      return (
                        <div key={subIndex} className="col-span-12">
                          <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12">
                              <div
                                id={index * 100 + subIndex}
                                className="pb-3 text-xl text-gray"
                              >
                                {subContent?.name}
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: subContent?.descriptions,
                                }}
                              ></div>
                            </div>
                            <SubContents
                              subContent={subContent}
                              selectedLanguage={selectedLanguage}
                              handleBtn={handleBtn}
                              languageList={languageList}
                            />
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
