import { Link } from "gatsby"
import React from "react"
import tw from "tailwind-styled-components"

const Image = tw.img`
  bg-white 
  rounded-full 
  max-w-[337px] 
  w-full 
  p-2
  xs:p-6
  md:p-[83.5px] 
  aspect-square
`

const Container = tw.div`
  flex 
  flex-col 
  max-w-md 
  text-justify 
  justify-center 
  pr-2
  text-base
  xs:text-xl
  w-11/12
`

export default function BuildContentInLeft({
  title,
  body,
  link,
  icon,
  logo,
  isBgClrExist = false,
}) {
  return (
    <div className="flex justify-around">
      <Container>
        <h3 className="pb-3 text-xl font-bold xs:text-xl md:text-2xl">
          {title}
        </h3>
        <p className="text-base font-medium text-secondary">{body}</p>
        {link && (
          <Link className="mt-3 flex items-center justify-end" to={link.url}>
            <h3 className="text-lg font-bold sm:text-xl">{link.title}</h3>
            <img className="pl-4" src={icon} alt="#icon" />
          </Link>
        )}
      </Container>
      <div className="flex items-center">
        {logo && !isBgClrExist ? (
          <img className="w-full max-w-[337px]" src={logo} alt="#au_logo" />
        ) : (
          <Image src="/dummyIcon.png" alt="#dummyIcon" />
        )}
      </div>
    </div>
  )
}
