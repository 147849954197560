import React, { useEffect, useState } from "react"
import SelectVersion from "../SelectVersion"
import { ContentList } from "../ContentTable"
import ApiDetails from "./ApiDetails"

const getLanguageList = (data, selectedDomainName) => {
  const languageData = data
    ?.filter(item => item.domainName === selectedDomainName)
    ?.map(value => {
      return value.language
    })
  const [[languages]] = languageData.length ? languageData : [[]]

  return languages
}

const getContentsData = (data, selectedDomainName) => {
  const contentsData = data
    ?.filter(item => item.domainName === selectedDomainName)
    .map(value => {
      return value.tableOfContents
    })
  return contentsData
}
export default function Developer(props) {
  const [selectedDomainName, setSelectedDomainName] =
    useState("Partner Invoice")
  const [selectedContentsData, setSelectedContentsData] = useState(
    getContentsData(props?.data, selectedDomainName)
  )
  const [languageList, setLanguageList] = useState(
    getLanguageList(props?.data, selectedDomainName)
  )

  useEffect(() => {
    setLanguageList(getLanguageList(props?.data, selectedDomainName))
  }, [props?.data, selectedDomainName])

  useEffect(() => {
    setSelectedContentsData(getContentsData(props?.data, selectedDomainName))
  }, [selectedDomainName, props?.data])

  const domainList = props?.data?.map(item => {
    return item.domainName
  })

  const handleChange = event => {
    setSelectedDomainName(event.target.value)
  }

  return (
    <div className="bg-primary">
      <div className="mb-10 md:mb-40">
        <div>
          <h1 className="pb-4 pt-16 text-2xl font-bold md:pb-7 md:text-5xl">
            Developer Hub
          </h1>

          <div className="pb-10 md:pb-20">
            <SelectVersion
              options={domainList}
              selectedOption={selectedDomainName}
              handleChange={handleChange}
              label={""}
            />
          </div>
          <div>
            {selectedContentsData.length &&
              selectedContentsData?.map((item, index) => {
                return (
                  <div key={index} className="grid grid-cols-12 gap-6">
                    <div className="col-span-3">
                      <ContentList
                        subTitle="List of the Api Contents"
                        contents={item}
                      />
                    </div>
                    <ApiDetails contents={item} languageList={languageList} />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
