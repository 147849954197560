import React from 'react'
import tw from "tailwind-styled-components"

const Container = tw.div`
  max-h-14 
  max-w-xs 
  gap-4 
  overflow-auto 
  overflow-x-auto 
  whitespace-nowrap 
  bg-black
`
export default function LanguageList({
  languageList,
  selectedLanguage,
  handleBtn,
}) {
  return (
    <Container>
      {languageList?.name?.map((item, index) => {
        return (
          <button
            key={index}
            className={`inline p-2 text-white ${
              item === selectedLanguage ? "bg-gray" : ""
            }`}
            onClick={() => handleBtn(item)}
          >
            {item}
          </button>
        )
      })}
    </Container>
  )
}
