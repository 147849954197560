import React from "react"
import tw from "tailwind-styled-components"
import { Link } from "gatsby"

const Image = tw.img`
  bg-white 
  rounded-full 
  max-w-[337px] 
  w-full 
  p-2
  xs:p-6
  md:p-[83.5px] 
  aspect-square
`
export default function BuildContentInRight({ title, body, icon, link, logo }) {
  return (
    <div className="flex justify-around">
      <div className="flex items-center">
        {logo && <Image src={logo} alt="#au_logo" />}

        {!logo && (
          <div>
            <Image src="/dummyIcon.png" alt="#dummyIcon" />
          </div>
        )}
      </div>
      <div className="flex w-11/12 max-w-md flex-col justify-center pl-2 text-justify">
        <h3 className="pb-3 text-xl font-bold xs:text-xl md:text-2xl">
          {title}
        </h3>
        <p className="text-base font-medium text-secondary">{body}</p>
        {link?.title && (
          <Link className="mt-3 flex items-center justify-end" to={link.url}>
            <h3 className="text-lg font-bold sm:text-xl">{link.title}</h3>
            <img className="pl-4" src={icon} alt="#icon" />
          </Link>
        )}
      </div>
    </div>
  )
}
