import React from "react"
import tw from "tailwind-styled-components"
import { useState } from "react"
import { useEffect } from "react"
import CardSmall from "./CardSmall"
import CardBig from "./CardBig"

const DivTitle = tw.div`
  absolute 
  text-center 
  inset-0
  flex
  justify-center
  items-center
`
const Container = tw.div`
  grid 
  grid-cols-6 
  gap-5 
  relative 
  -top-28
  sm:-top-40
  md:-top-28 
  xl:-top-32
  mx-6
  sm:mx-0
  backdrop-blur
`

const ContainerNav = tw.div`
  relative
  bg-no-repeat 
  bg-left 
  bg-contain
  mx-6
  min-h-[80vh]
  desktop:min-h-[65vh]
  `

export default function Home(props) {
  const [shape, setShape] = useState([])
  const [smallCards, setSmallCards] = useState([])
  const [bigCards, setBigCards] = useState([])

  const data = props?.data?.cards

  useEffect(() => {
    const [shapeData] = data?.filter(item => item?.card?.name === "Shape")
    const allSmallCard = data?.filter(
      item =>
        item?.card?.cardSize !== "width: 475px" && item?.card.name !== "Shape"
    )
    setSmallCards(allSmallCard)
    const allBigCard = data?.filter(
      item =>
        item?.card?.cardSize === "width: 475px" && item?.card.name !== "Shape"
    )
    setBigCards(allBigCard)
    setShape(shapeData)
  }, [data])

  return (
    <div className="bg-primary">
      {shape?.cardImg?.sourceUrl && (
        <ContainerNav
          style={{ backgroundImage: `url(${shape?.cardImg?.sourceUrl})` }}
        >
          <DivTitle>
            <h1 className="mb-40 max-w-3xl text-4xl sm:text-5xl md:text-6xl">
              {shape?.cardTitle?.title}
            </h1>
          </DivTitle>
        </ContainerNav>
      )}
      <Container>
        {bigCards?.map((item, index) => {
          return <CardBig data={item} key={index} />
        })}
        <div className="col-span-2 col-end-6"></div>
        {smallCards?.map((item, index) => {
          return <CardSmall data={item} key={index} />
        })}
      </Container>
    </div>
  )
}
