import React, { useEffect, useState } from "react"

import SelectVersion from "./SelectVersion"
import { ContentList, ContentDetails } from "./ContentTable"

export default function Agreements(props) {
  const { data } = props
  const [options, setOptions] = useState(["Current (June 2020)"])
  const [selectedVersionContent, setSelectedVersionContent] = useState([])
  const [selectedOption, setSelectedOption] = useState(options[0])

  useEffect(() => {
    const allVersion = data?.map(item => {
      return item.versionHistory
    })
    setOptions(allVersion)
  }, [data])

  useEffect(() => {
    setSelectedOption(options[0])
  }, [options])

  useEffect(() => {
    const selectedVersionData = data?.filter(
      item => item.versionHistory === selectedOption
    )
    setSelectedVersionContent(...selectedVersionData)
  }, [selectedOption, data])

  const handleChange = event => {
    setSelectedOption(event.target.value)
  }

  return (
    <div className="bg-primary">
      <div className="mb-10 md:mb-40">
        <div>
          <h1 className="pb-4 pt-16 text-2xl font-bold md:pb-7 md:text-5xl">
            Terms & Conditions
          </h1>
          <div className="pb-10 md:pb-20">
            <SelectVersion
              options={options}
              selectedOption={selectedOption}
              handleChange={handleChange}
              label="Version history"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-12 text-justify md:grid-cols-3">
          <div className="col-span-1">
            <ContentList
              title={selectedVersionContent?.title}
              subTitle={selectedVersionContent?.subTitle}
              contents={selectedVersionContent?.tableOfContents}
            />
          </div>
          <div className="col-span-2 mt-14 p-0 md:pl-14">
            <ContentDetails table={selectedVersionContent?.tableOfContents} />
          </div>
        </div>
      </div>
    </div>
  )
}
