import React, { useEffect, useState } from "react"
import CardWrapper from "./CardWrapper"

export default function CardBig({ data }) {
  const [card, setCard] = useState([])
  const [cardImg, setCardImg] = useState([])
  const [description, setDescription] = useState("")
  const [title, setTitle] = useState([])

  useEffect(() => {
    const { card, cardImg, cardTitle, description } = data
    setCard(card)
    setCardImg(cardImg)
    setDescription(description)
    setTitle(cardTitle)
  }, [data])

  return (
    <CardWrapper bgclr={card?.backgroundColor}>
      <h3
        className={`pb-10 text-center text-2xl ${
          title?.color === "white" ? "text-white" : ""
        } font-bold xs:text-3xl  sm:text-start`}
      >
        {title.title}
      </h3>
      <p className="pb-10 text-center text-base font-normal sm:text-start">
        {description}
      </p>
      {cardImg?.sourceUrl && <img src={cardImg?.sourceUrl} alt="#imge" />}
    </CardWrapper>
  )
}
