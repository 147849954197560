import React from "react"
import tw from "tailwind-styled-components"

import BuildContentInLeft from "./BuildContentInLeft"
import BuildContentInRight from "./BuildContentInRight"

const Container = tw.div`
  ${p =>
    p.$index === 1
      ? "border-l-2 border-r-2 h-28 xs:h-40 md:h-64 border-gray"
      : ""}
  flex 
  flex-col 
  gap-4 
  justify-center 
  items-center 
  w-1/3 
  `
const H2 = tw.div`
  text-center 
  font-bold 
  text-2xl
  xs:text-4xl 
  md:text-[54px] 
  leading-[66px] 
  mb-16
  `

export default function company({ ...data }) {
  const { about, block } = data

  return (
    <div className="bg-primary">
      <div className="mb-20 mt-16 md:mb-60">
        <div className="mb-16 flex flex-col gap-4 md:mb-28 md:gap-10">
          <H2>About Esntl</H2>
          <div className="flex w-full justify-around">
            {about?.map((item, index) => {
              return (
                <Container key={index} $index={index}>
                  <h3 className="text-2xl font-bold xs:text-4xl md:text-6xl">
                    {item.amount}
                  </h3>
                  <h5 className="text-center text-base md:text-3xl">
                    {item.name}
                  </h5>
                </Container>
              )
            })}
          </div>
        </div>

        <div className="flex flex-col gap-16">
          {block?.map((item, index) => {
            return item.isContentInLeft === "Left" ? (
              <BuildContentInLeft
                title={item.title}
                body={item.description}
                logo={item?.image?.sourceUrl}
                key={index}
                isBgClrExist={true}
              />
            ) : (
              <BuildContentInRight
                title={item.title}
                body={item.description}
                logo={item?.image?.sourceUrl}
                key={index}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
