import React from "react"

export default function ApiContentDetails({ item }) {
  return (
    <div>
      {item?.block?.length && (
        <div>
          <h1 className="bg-white-rgba px-5 text-2xl">{item.block?.name}</h1>
          <h2 className="text-xl text-gray">{item.block[0]?.descriptions}</h2>
          {item?.block[0]?.table?.map((value, index) => {
            return (
              <div key={index} className="grid grid-cols-12">
                <div
                  className={`col-span-3 inline py-2 pl-4 ${
                    index === 0 ? "font-bold" : ""
                  }`}
                >
                  {value.column1}
                </div>
                <div
                  className={`col-span-9 inline py-2 pl-4 ${
                    index === 0 ? "font-bold" : ""
                  }`}
                >
                  {value.column2}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
