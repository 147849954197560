import React from "react"
import tw from "tailwind-styled-components"

import BuildContentInLeft from "./BuildContentInLeft"
import BuildContentInRight from "./BuildContentInRight"

const Container = tw.div`
  flex 
  flex-col 
  justify-between 
  pb-20 
  md:pb-60 
  pt-16
  bg-primary 
  text-xs 
  md:text-xl
  gap-11
`

const H1 = tw.h1`
  mb-16
  font-bold 
  text-2xl 
  md:text-5xl 
  text-center
`

export default function Brand(props) {
  const data = props.data.block

  return (
    <div className="bg-primary">
      <Container>
        <H1>Our Brands</H1>
        {data.map((item, index) => {
          return item.isContentInLeft === "Left" ? (
            <BuildContentInLeft
              title={item.title}
              body={item.description}
              link={item?.link}
              icon={item?.icon?.sourceUrl}
              logo={item?.image?.sourceUrl}
              key={index}
            />
          ) : (
            <BuildContentInRight
              title={item.title}
              body={item.description}
              link={item?.link}
              icon={item?.icon?.sourceUrl}
              logo={item?.image?.sourceUrl}
              key={index}
            />
          )
        })}
      </Container>
    </div>
  )
}
