import React from "react"
import tw from "tailwind-styled-components"

import { allData } from "../configs/organizationContent"

const H2 = tw.h2`
  text-4xl 
  md:text-[54px] 
  font-bold 
  text-center 
  mb-14
`

const Container = tw.div`
  grid 
  grid-cols-2 
  md:grid-cols-3 
  gap-12 
  lg:gap-x-24 
  gap-y-7
`

export default function Investors() {
  return (
    <div className="my-12 md:my-36">
      <H2>Investors</H2>
      <Container>
        {allData.investors.map(item => {
          return <div key={item} className="max-w-[264] bg-white py-8"></div>
        })}
      </Container>
    </div>
  )
}
