import React from "react"
import tw from "tailwind-styled-components"

const Container = tw.div`
  ${({ bgclr }) =>
    bgclr === "yellow" ? `bg-orange-normal/80` : "bg-white-rgba"}
  col-span-6 
  sm:col-span-3 
  rounded-[20px]
  flex 
  flex-col
  py-10 
  md:py-14 
  px-4
  xs:px-10 
  md:px-16
`

export default function CardWrapper({ bgclr, children }) {
  return <Container bgclr={bgclr}>{children}</Container>
}
