import React from "react"
import { graphql } from "gatsby"
import Partner from "../components/Partner"
import Brand from "../components/Brand"
import Agreements from "../components/Agreements"
import Company from "../components/Company"
import Leadership from "../components/Leadership"
import { Seo } from "../components/SEO"
import Developer from "../components/DevelopersHub/Developer"

export default function Page(props) {
  const { wpPage } = props.data || {}
  const { acfBlock, acfAbout, acfEmpStatus, acfBusiness, acfHomePage, acfDeveloperHub } =
    wpPage || {}

  return (
    <div>
      {acfHomePage?.cards && <Partner data={acfHomePage} />}
      {acfBlock?.block && !acfAbout?.about && <Brand data={acfBlock} />}
      {acfAbout?.about && acfBlock?.block && (
        <Company about={acfAbout.about} block={acfBlock?.block} />
      )}
      {acfEmpStatus?.empStatus && <Leadership data={acfEmpStatus?.empStatus} />}
      {acfBusiness?.version && <Agreements data={acfBusiness.version} />}
      {acfDeveloperHub?.apiDocs && <Developer data={acfDeveloperHub.apiDocs} />}
    </div>
  )
}

export function Head(props) {
  return <Seo pathName={props?.location?.pathname} />
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      acfHomePage {
        cards {
          card {
            backgroundColor
            cardSize
            name
          }
          cardImg {
            altText
            sourceUrl
            slug
          }
          cardTitle {
            color
            title
          }
          description
        }
      }
      acfBlock {
        block {
          fieldGroupName
          title
          description
          link {
            target
            title
            url
          }
          icon {
            altText
            slug
            sourceUrl
          }
          image {
            altText
            slug
            sourceUrl
          }
          isContentInLeft
        }
      }
      acfAbout {
        about {
          amount
          name
          fieldGroupName
        }
      }
      acfEmpStatus {
        empStatus {
          title
          empImage {
            altText
            slug
            sourceUrl
          }
          empName
          empPosition
        }
      }
      acfBusiness {
        version {
          versionHistory
          tableOfContents {
            name
            tableDescriptions
          }
          subTitle
          title
        }
      }

      acfLayout {
        button
        services {
          serviceIcon {
            altText
            sourceUrl
            slug
            title
          }
          serviceName
        }
        policy {
          policyName {
            target
            title
            url
          }
        }
        termscontent
      }
      acfDeveloperHub {
        apiDocs {
          domainName
          language {
            name
          }
          tableOfContents {
            name
            descriptions
            block {
              descriptions
              table {
                column1
                column2
              }
            }
            subContent {
              descriptions
              name
              api {
                name
                codes {
                  code
                  languageName
                }
                gridContents {
                  column1
                  column2
                  column3
                  column4
                  column5
                }
              }
            }
          }
        }
      }
    }
  }
`
