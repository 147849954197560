import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

export function Seo({ pathName, children }) {
  const [hostName, setHostName] = useState("")
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          description
          headline
          image
          pathPrefix
          publisher
          siteUrl
          siteLanguage
          title
          titleTemplate
          twitter
        }
      }
    }
  `)

  const {
    author,
    description,
    headline,
    image,
    pathPrefix,
    publisher,
    siteUrl,
    title,
    titleTemplate,
    twitter,
  } = site.siteMetadata || {}

  useEffect(() => {
    const hstName = siteUrl + pathName
    setHostName(hstName)
  }, [pathName, siteUrl])

  return (
    <>
      <title>{title}</title>
      <link id="icon" rel="icon" href={image} />
      <link rel="alternate" href={hostName} />
      <meta name="author" content={publisher} />
      <meta name="headline" content={headline} />
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      <meta name="pathPrefix" content={pathPrefix} />
      <meta name="titleTemplate" content={titleTemplate} />
      <meta name="twitter" content={twitter} />
      {children}
    </>
  )
}
