import React from "react"
import tw from "tailwind-styled-components"

const Container = tw.div`
  flex 
  flex-col 
  lg:flex-row 
  justify-between 
  items-center 
  gap-4 
  mt-10 
  md:mt-20
`

const Imge = tw.img`
  max-w-[300px] 
  w-full 
  aspect-square 
  p-7 
  md:p-9 
  rounded-full 
  bg-white
`
export default function Leaders(props) {
  const { data } = props
  return (
    <div>
      <h2 className="text-center text-3xl font-bold md:text-5xl">Leadership</h2>
      <Container>
        {data?.map((item, index) => {
          return (
            <div key={index} className="flex flex-col gap-4 md:gap-8">
              <Imge src={item.empImage.sourceUrl} alt="#dummyIcon" />
              <div className="flex flex-col items-center">
                <h3 className="text-xl font-bold">{item.empName}</h3>
                <p className="pt-2 text-base text-secondary">
                  {item.empPosition}
                </p>
              </div>
            </div>
          )
        })}
      </Container>
    </div>
  )
}
