import React, { useState, useEffect } from "react"

import Investors from "./Investors"
import Leaders from "./Leaders"
import Team from "./Team"

export default function Leadership(props) {
  const { data } = props
  const [leadership, setLeadership] = useState([])
  const [team, setTeam] = useState([])
  useEffect(() => {
    const leadershipData = data?.filter(item => item.title === "Leadership")
    setLeadership(leadershipData)
    const teamData = data?.filter(item => item.title === "Team")
    setTeam(teamData)
  }, [data])

  return (
    <div className="bg-primary">
      <div className="mt-16">
        <Leaders data={leadership} />
        <Team data={team} />
        <Investors />
      </div>
    </div>
  )
}
